body {
  padding-right: 0 !important;
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
}

.ctm-heading-formatting h4,
.ctm-heading-formatting h3 {
  font-size: 24px;
  font-weight: 400;
}

.ctm-bkg-senator {
  background: #4b575f;
  /* background-color: #eeeeee; */
}
.ctm-bkg-personaltotal {
  background: #2e2e2e;
  /* background-color: #eeeeee; */
}
.ctm-bkg-hrcg {
  background: #7f878a;
  /* background-color: #eeeeee; */
}
.ctm-bkg-matchflix {
  position: fixed;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: fixed;
  width: 100%;
}
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}
#loading-indicator-overlay {
  position: absolute;
  z-index: 10000000;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(50, 50, 50, 0.8);
}

#loading-indicator-container {
  position: absolute;
  top: 35%;
  left: 50%;
  width: 100px;
  margin-left: -50px;
  height: 100px;
  color: #39a9dc;
}
#container-body {
  min-height: 100vh;
  position: relative;
}
#body {
  /* padding: 96px; */
  padding-top: 96px;
  padding-bottom: 60px; /* Height of the footer */
}
#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Height of the footer */
}
.jobposting-main-title {
  font-size: 2rem;
}

.print-only {
  display: none;
}

.share-button-list > * {
  margin-right: 10px !important;
}
html {
  font-size: 0.8rem;
}
@media (min-width: 576px) {
  html {
    font-size: 1rem;
  }
}

@media (min-width: 768px) {
  html {
    font-size: 1rem;
  }
}

@media (min-width: 992px) {
  .jobposting-main-title {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.6rem;
  }
}

@media print {
  .hide-on-print {
    display: none;
  }

  .print-only {
    display: block;
  }

  .ctm-bkg-senator {
    /* background: #4b575f; */
    background: none;
  }
  .ctm-bkg-personaltotal {
    /* background: #2e2e2e; */
    background: none;
  }
  .ctm-bkg-hrcg {
    /* background: #7f878a; */
    background: none;
  }

  #header {
    display: none;
  }

  #footer {
    display: none;
  }

  .job-posting-detail__header-image {
    /* -webkit-print-color-adjust: exact; */
    display: none;
  }

  @page {
    margin: 0;
  }
  body {
    margin: 0;
  }
}
